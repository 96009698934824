import React from 'react';

const IconLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="271.02" height="310.034" viewBox="0 0 271.02 310.034">
    <g id="F" transform="translate(-224.695 -85.624)">
      <text id="F-2" data-name="F" transform="translate(299 315)" fill="#63ffda" font-size="200" font-family="Helvetica"><tspan x="0" y="0">F</tspan></text>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
      <path id="Path_1" data-name="Path 1" d="M435.651,850.174l126.064-68.158V637.388L437.036,563.135,310.7,632.124v144.35l61.55,36.3Z" transform="translate(-76 -466)" fill="none" stroke="#63ffda" stroke-width="20" />
    </g>
  </svg>


);

export default IconLogo;

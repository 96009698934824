import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(11.000000, 5.000000)">
        <path
          d="M33.592,61h-5.05V30.3H45.065V61Zm0-17.8H45.065s-.019-8.421,0-8.4H33.592Zm0,4.3V61H45.065V47.5Z"
          fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M50,5,11,27V72L50,95,89,73V28Z"
      />
    </g>
  </svg>
);

export default IconLoader;